<template>
   <v-container fluid>
    <v-card class="elevation-5">
      <v-card-title color="primary">
        Booking Paramemters
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-form ref="parametersForm" v-model="formValue">
          <v-row>
            <v-col md="4">
               <v-text-field
                v-model="entranceFee"
                label="Entrance Fee"
                type="number"
                :rules="rules.required"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
               <v-text-field
                v-model="maxGuest"
                label="Max Guest"
                type="number"
                :rules="rules.required"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary darken-1"
          :loading="loading"
          @click.stop="saveForm"
          :disabled="!(formValue || loading)"
        >
          Save
        </v-btn>
      </v-card-actions>
      <v-snackbar
        top
        right
        v-model="snackbar"
        :timeout="4000"
        :color="snackbarColor"
      >
        {{ snackbarText }}
        <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
    </v-card>
   </v-container>
</template>
<script>
import { Firestore } from "../plugins/firebaseInit";
export default {
  name: "BookingParameters",
   data() {
    return {
      formValue: true,
      entranceFee: 0,
      maxGuest: 0,
      bookingEmail: "",
      confirmationEmail: "",
      loading: false,
      rules: {
        required: [
          value => !!value || "Required."
        ],
      },
      snackbar: false,
      snackbarText: "",
      snackbarColor: "info",
    }
  },
  created() {
    this.firestoreConfigs = Firestore
      .doc("configs/booking")
      .onSnapshot(snapshot => {
        this.entranceFee = snapshot.get("entranceFee");
        this.maxGuest = snapshot.get("maxGuest");
      });
    // Firestore
    //   .doc("emailTemplates/booking").get().then( doc =>{
    //     this.bookingEmail = doc.get("html");
    //   })
  },
  destroyed: function() {
    this.firestoreConfigs();
  },
  methods: {
    saveForm() {
      this.loading = true;
      if (this.$refs.parametersForm.validate()) {
        Firestore.doc("configs/booking")
        .set({
          entranceFee: this.entranceFee,
          maxGuest: this.maxGuest
        }, 
        { merge: true }).then(() => {
          this.snackbarColor = "success";
          this.snackbarText = "Booking Parameters has been updated ";
          this.snackbar = true;
          this.loading = false
        })
      } else {
          this.loading = false
          this.snackbarColor = "error";
          this.snackbarText = "Error in updating Booking Parameters ";
          this.snackbar = true;

      }
    }
  }
}
</script>
